import * as React from "react";
import { observer } from 'mobx-react';
import useStores from "../../hooks/use-stores";
import PropertyStore from "../../stores/property-store";
// this file is an example to map list of data from the backend

// fetch('http://realestateadminapi.auokka.com/Agent/ListAgent')
//   .then((response) => response.json())
//   .then((data) => console.log(data));

// async function funcName(url){
//   const response = await fetch(url);
//   var data = await response.json();
//   }

const GetData = observer(() => {
  const { propertyStore } = useStores();
  React.useEffect(() => {
    propertyStore.getPropertyList();
    console.log("current:", propertyStore.propertyList.at(1))
  }, []);

  return (
    <>
      <div className="flex h-[100vh]">
        <div className="w-full">123</div>
        <div className="w-[18px] bg-theme-gray">123</div>
      </div>
      <div>data</div>
      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>title</th>
            <th>description</th>
          </tr>
        </thead>
        <tbody>
          {propertyStore.propertyList.map((property, index) =>
            <tr key={index}>
              <td>{property.propertyId}</td>
              <td>{property.title}</td>
              <td>{property.updateAt.split("T")[0]}</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
});
export default GetData;